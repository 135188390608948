<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">农业园区服务面积/分类占比</span>
    </div>
    <div class="top">
      <div class="top-item" v-for="(item, index) in topdata" :key="index">
        <img src="../../assets/triangle.png" alt="" class="top-icon">
        <div class="top-text-content">
          <div class="top-text">{{ item.text }}</div>
          <div class="num-content">
            <span class="top-num" v-change="{ eUnit: '' }">{{ item.num }}</span>
            <span class="top-unit">{{ item.unit }}</span>
          </div>
        </div>
        <img :src="item.url" alt="" class="top-img">
      </div>
    </div>
    <div class="item" v-for="(item, index) in data" :key="index">
      <div class="img-content">
        <div class="center">
          <img :src="item.url" alt="" class="item-img">
          <div class="img-number-content">
            <span class="img-number" v-change="{ eUnit: '' }">{{ item.number }}</span>
            <span class="img-text">{{ item.text }}</span>
          </div>
        </div>
        <div class="item-title">{{ item.title }}</div>
      </div>
      <div class="item-text-box">
        <div class="item-text" v-for="(textItem, j) in item.itemText" :key="j">
          <div class="item-color" :style="{ 'background-color': textItem.color }"></div>
          <div class="text-content">{{ textItem.text }}</div>
          <div class="item-line"></div>
          <div class="item-num">{{ textItem.num }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topdata: [
        {
          text: '农业园区数量',
          num: 288,
          unit: '个',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon1.png'
        },
        {
          text: '服务面积',
          num: 32800,
          unit: '亩',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon2.png'
        }
      ],
      data: [
        {
          itemText: [
            {
              color: '#00c4c5',
              text: '小麦',
              num: '48%',
            },
            {
              color: '#7194b8',
              text: '玉米',
              num: '22%',
            },
            {
              color: '#b4def2',
              text: '水稻',
              num: '13%',
            }
          ],
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft2-icon1.png',
          title: '粮食面积',
          number: 14700,
          text: '亩'
        },
        {
          itemText: [
            {
              color: '#00c4c5',
              text: '苹果',
              num: '48%',
            },
            {
              color: '#7194b8',
              text: '猕猴桃',
              num: '22%',
            },
            {
              color: '#b4def2',
              text: '葡萄',
              num: '13%',
            },
            {
              color: '#d0b079',
              text: '花椒',
              num: '13%',
            }
          ],
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft2-icon2.png',
          title: '经济林果面积',
          number: 10942,
          text: '亩'
        },
        {
          itemText: [
            {
              color: '#00c4c5',
              text: '黄芪',
              num: '48%',
            },
            {
              color: '#7194b8',
              text: '天麻',
              num: '22%',
            },
            {
              color: '#b4def2',
              text: '三七',
              num: '13%',
            }
          ],
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft2-icon3.png',
          title: '中药材面积',
          number: 7158,
          text: '亩'
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  margin-bottom: 24px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;

    .top-item {
      width: 190px;
      height: 70px;

      position: relative;
      display: flex;
      align-items: center;

      .top-icon {
        position: absolute;
        top: 5px;
        left: 5px;
      }

      .top-img {
        position: absolute;
        right: 12px;
        top: 15px;
      }

      .top-text-content {
        margin-left: 26px;

        .top-text {
          font-size: 14px;
          font-weight: 400;
          color: rgba(217, 231, 255, 1);
          margin-bottom: 6px;
        }

        .top-num {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 2px;
          color: rgba(79, 240, 255, 1);
          margin-right: 4px;
        }

        .top-unit {
          font-size: 12px;
          font-weight: 400;
          color: rgba(217, 231, 255, 1);
        }

        z-index: 99;
      }

      .top-img {
        z-index: 99;
      }
    }

    .top-item::after {
      content: "";
      background-image: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft1-bg.png'); // 背景图片的路径
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -99;
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;

    .img-content {
      width: 151px;
      height: 143px;
      margin-right: 20px;

      // text-align: center;
      .center {
        width: 100%;
        height: 113px;
        background: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft2-bg.png') 0 0 no-repeat;
        background-size: cover;
        margin: 22px 0 9px 0;
        position: relative;

        .item-img {
          width: 40px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: -13px;
          transform: translate(-30%, 0);
        }

        .img-number-content {
          width: 90px;
          position: absolute;
          left: 50%;
          top: 30px;
          transform: translate(-43%, 0);
          text-align: center;

          .img-number {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 2px;
            color: rgba(255, 255, 255, 1);
            margin-left: 4px;
          }

          .img-text {
            font-size: 12px;
            font-weight: 400;
            color: rgba(217, 231, 255, 1);
          }
        }
      }

      .item-title {
        text-align: center;
        padding-left: 17px;
        margin-top: -15px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(79, 240, 255, 1);
      }
    }



    .item-text {
      width: 245px;
      height: 24px;
      background: rgba(108, 128, 151, 0.1);
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 11px;
      // margin-left: -10px;

      .item-color {
        width: 7px;
        height: 7px;
      }

      .item-line {
        width: 94px;
        height: 1px;
        border: 1px solid rgba(102, 225, 223, 1);
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

}
</style>