<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">新增农人数量</span>
    </div>
    <CenterLeft2Chart/>
  </div>
</template>

<script>
import CenterLeft2Chart from '@/components/echart/centerLeft/centerLeft2Chart'
export default {
  components:{
    CenterLeft2Chart
  },
  data() {
    return {
      data: [
        {
          title: '甘肃',
          number: 3402,
          unit: '人'
        },
        {
          title: '宁夏',
          number: 2030,
          unit: '人'
        },
        {
          title: '陕西',
          number: 1200,
          unit: '人'
        },
        {
          title: '新疆',
          number: 650,
          unit: '人'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  margin-top: -20px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    // display: flex;
    // align-items: center;
    margin-top: 15px;

    .item {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .item-title {
        font-size: 12px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        margin-right: 10px;
      }

      .item-content {
        position: relative;
        margin-right: 25px;

        .rectangle1 {
          width: 282px;
          height: 4px;
          opacity: 1;
          background: rgba(135, 158, 169, 0.15);
        }

        .rectangle2 {
          width: 282px;
          height: 4px;
          opacity: 1;
          background: linear-gradient(270deg, rgba(51, 204, 204, 1) 0%, rgba(31, 89, 89, 0.25) 100%);
          position: absolute;
          top: 0;
          left: 0;
        }

        .round1 {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid rgba(82, 204, 204, 0.4);
          position: absolute;
          top: -174%;
          left: 274px;
        }

        .round2 {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: rgba(80, 230, 230, 1);
          box-shadow: 0px 0px 10px 3px rgba(25, 96, 102, 1), 0px 0px 4px 1px rgba(32, 120, 128, 1);
          position: absolute;
          top: 0;
          left: 282px;
        }

        .rectangle3 {
          width: 215px;
          height: 4px;
          opacity: 1;
          background: linear-gradient(180deg, rgba(122, 163, 204, 1) 0%, rgba(96, 112, 128, 0.1) 100%);
          position: absolute;
          top: 0;
          left: 0;
        }

        .round3 {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid rgba(82, 204, 204, 0.4);
          position: absolute;
          top: -174%;
          left: 207px;
        }

        .round4 {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: rgba(179, 217, 255, 1);
          box-shadow: 0px 0px 10px 3px rgba(77, 103, 129, 1), 0px 0px 4px 1px rgba(95, 124, 153, 1);
          position: absolute;
          top: 0;
          left: 215px;
        }

        .rectangle4 {
          width: 174px;
          height: 4px;
          opacity: 1;
          background: linear-gradient(270deg, rgba(51, 204, 204, 1) 0%, rgba(31, 89, 89, 0.25) 100%);
          position: absolute;
          top: 0;
          left: 0;
        }

        .round5 {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid rgba(82, 204, 204, 0.4);
          position: absolute;
          top: -174%;
          left: 167px;
        }

        .round6 {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: rgba(179, 217, 255, 1);
          box-shadow: 0px 0px 10px 3px rgba(25, 96, 102, 1), 0px 0px 4px 1px rgba(32, 120, 128, 1);
          position: absolute;
          top: 0;
          left: 174px;
        }

        .rectangle5 {
          width: 96px;
          height: 4px;
          opacity: 1;
          background: linear-gradient(180deg, rgba(122, 163, 204, 1) 0%, rgba(96, 112, 128, 0.1) 100%);
          position: absolute;
          top: 0;
          left: 0;
        }

        .round7 {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid rgba(82, 204, 204, 0.4);
          position: absolute;
          top: -174%;
          left: 88px;
        }

        .round8 {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: rgba(179, 217, 255, 1);
          box-shadow: 0px 0px 10px 3px rgba(77, 103, 129, 1), 0px 0px 4px 1px rgba(95, 124, 153, 1);
          position: absolute;
          top: 0;
          left: 96px;
        }
      }

      .peopleNumber {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.6px;
        color: rgba(255, 255, 255, 1);
        margin-right: 8px;
      }

      .unit {
        font-size: 12px;
        font-weight: 500;
        color: rgba(143, 171, 191, 1);
        opacity: 0.6;
        margin-left: 5px;
      }
    }
  }
}
</style>
