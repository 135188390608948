<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        // xdata: ['返利人员', '下线人员', 123, 123, 123, 123],
        // data: [100, 200, 23, 73, 123, 123],
        dimensions: ['product', '新增农人数量',],
        source: [
          { product: '一月', 新增农人数量: 180,},
          { product: '二月', 新增农人数量: 290},
          { product: '三月', 新增农人数量: 130},
          { product: '四月', 新增农人数量: 210},
          { product: '五月', 新增农人数量: 270},
          { product: '六月', 新增农人数量: 150}
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.bigbox {
  margin-top: 10px;
}
</style>