<template>
  <div>
    <Echart :options="options" id="centreRight2Chart1" height="200px" width="400px"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          legend: {
            right: 0
          },
          xAxis: {
            type: 'category',
            data: newData.indicatorData
          },
          yAxis: {
            name: '单位',
            splitLine: {    // gird 区域中的分割线
              show: true,   // 是否显示
              lineStyle: {
                color: 'rgba(143, 171, 191, 1)',
                width: 0.7,
                type: 'solid'   // dashed
              }
            },
            axisLine: {
              show: false
            },
            type: 'value',
            scale: true,
            max: 40,
            min: 0,
            splitNumber: 2,
            boundaryGap: [0.2, 0.2]
          },
          series: [
            {
              name: '农产品',
              data: newData.seriesData1,
              type: 'line',
              smooth: true,
              symbol: 'none', 
              itemStyle: {
                normal: {
                  color: '#5784b0',
                  areaStyle: {
                    //color: '#94C9EC'
                    color: {
                      type: 'linear',
                      x: 0, //右
                      y: 0, //下
                      x2: 0, //左
                      y2: 1, //上
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgba(112, 158, 204, 0.5)' // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: 'rgba(83, 105, 128, 0)' // 100% 处的颜色
                        }
                      ]
                    }
                  }
                }
              },
            },
            {
              name: '农旅项目',
              data: newData.seriesData2,
              type: 'line',
              smooth: true,
              symbol: 'none', 
              itemStyle: {
                normal: {
                  color: '#00c8c7',
                  areaStyle: {
                    //color: '#94C9EC'
                    color: {
                      type: 'linear',
                      x: 0, //右
                      y: 0, //下
                      x2: 0, //左
                      y2: 1, //上
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgba(38, 191, 191, 0.5)' // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: 'rgba(31, 89, 89, 0)' // 100% 处的颜色
                        }
                      ]
                    }
                  }
                }
              },
            },
            {
              name:'农服项目',
              data: newData.seriesData3,
              type: 'line',
              smooth: true,
              symbol: 'none', 
              itemStyle: {
                normal: {
                  color: '#4f5cf7',
                  areaStyle: {
                    //color: '#94C9EC'
                    color: {
                      type: 'linear',
                      x: 0, //右
                      y: 0, //下
                      x2: 0, //左
                      y2: 1, //上
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgba(0, 85, 255, 1)' // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: ' rgba(31, 89, 89, 0)' // 100% 处的颜色
                        }
                      ]
                    }
                  }
                }
              },
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>