<template>
  <div>
    <Echart :options="options" id="centreLeft1Chart" height="200px" width="400px"></Echart>
  </div>
</template>

<script>
import echarts from 'echarts'
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          legend: {
            right: 0
          },
          tooltip: {},
          dataset: {
            dimensions: newData.dimensions,
            source: newData.source
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            name: '人',
            splitLine: {    // gird 区域中的分割线
              show: true,   // 是否显示
              lineStyle: {
                color: 'rgba(143, 171, 191, 1)',
                width: 0.7,
                type: 'solid'   // dashed
              }
            },
            axisLine: {
              show: false
            },
            type: 'value',
            scale: true,
            max: 300,
            min: 0,
            splitNumber: 3,
            boundaryGap: [0.2, 0.2]
          },
          series: [
            {
              type: 'pictorialBar',
              barWidth: 60,
              barCategoryGap: '-80%',/*多个并排柱子设置柱子之间的间距*/
              symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
              itemStyle: {
                borderColor: "rgba(21, 154, 255, 1)",
                borderWidth: 1,
                // 渐变色
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(0, 58, 255, 0)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(21, 154, 255, 1)'
                  }
                ])
              }
            },
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped></style>