<template>
  <div id="centerRight1">
    <div class="title">
      <span class="text">数字农管监控设备</span>
    </div>
    <div class="top">
      <div class="top-item" v-for="(item, j) in topData" :key="j">
        <div class="top-img">
          <img :src="item.url" alt="" class="top-icon">
        </div>
        <div class="top-content">
          <div class="text">{{ item.text }}</div>
          <div class="num-content">
            <span class="top-num" v-change="{eUnit:''}">{{ item.num }}</span>
            <span class="top-unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in data" :key="index">
        <img :src="item.url" alt="">
        <div class="num">
          <div class="number">
            <span class="number-content" v-change="{eUnit:''}">{{ item.number }}</span>
            <span class="unit">个</span>
          </div>
          <div class="num-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topData: [
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-top-icon2.png',
          text: '设备总数',
          num: 186,
          unit: '个'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-top-icon3.png',
          text: '设备分类',
          num: 22,
          unit: '个'
        }
      ],
      data: [
        {
          number: 39,
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/device-icon6.png',
          text: '传感器'
        },
        {
          number: 9,
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/device-icon5.png',
          text: '节能灌溉'
        },
        {
          number: 28,
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/device-icon4.png',
          text: '卷帘卷膜'
        },
        {
          number: 64,
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/device-icon3.png',
          text: '摄像头'
        },
        {
          number: 28,
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/device-icon2.png',
          text: '补光灯'
        },
        {
          number: 18,
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/device-icon1.png',
          text: '捕虫灯'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
  width: 400px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .top {
    margin-top: 11px;
    margin-bottom: 17px;
    display: flex;
    align-items: center;

    .top-item {
      width: 50%;
      padding-left: 10px;
      display: flex;
    }

    .top-img {
      width: 84px;
      height: 84px;
      background: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/right-top-icon1.png') 0 0 no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 9px;
    }

    .top-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .text {
        font-size: 14px;
        font-weight: 400;
        color: rgba(217, 231, 255, 1);
        margin-bottom: 7px;
      }

      .top-num {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 2px;
        color: rgba(255, 255, 255, 1);
        margin-right: 7px;
      }

      .top-unit {
        font-size: 12px;
        font-weight: 400;
        color: rgba(217, 231, 255, 1);
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: 16px;

    .number {
      color: rgba(79, 240, 255, 1);

      .number-content {
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 1px;
      }

      .unit {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    .num-text {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.4px;
      margin-top: 5px;
    }

  }
}
</style>
